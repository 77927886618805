const newBike = {
  id: null,
  manufacturerId: null,
  model: "",
  price: "",
};

module.exports = {
  newBike,
};
